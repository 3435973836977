import React, { useContext, useLayoutEffect, useState } from 'react'
import api, { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { context } from '~/layouts'
import { scroller } from 'react-scroll'
import { Tabs, Form, Button, Link } from '~/components'
import * as st from '~/assets/styl/Contact.module.styl'
import * as st1 from '~/assets/styl/NotFound.module.styl'

const ContactForms = ({ location: { pathname } }: { location: Location }) => {
  const { result: config }: { result: { whatsapp: string; phone: string } } =
    useApi(
      {
        result: useStaticQuery(graphql`
          query {
            allConfigurations {
              nodes {
                whatsapp
                phone
              }
            }
          }
        `).allConfigurations.nodes[0],
      },
      'configurations'
    )
  // const {
  //   constructions,
  // }: { constructions: Array<{ name: string; id: string }> } = useApi(
  //   { constructions: [] },
  //   'constructions'
  // )
  const { areas }: { areas: Array<{ name: string; id: string }> } = useApi(
    { areas: [] },
    'areas'
  )
  const { states }: { states: Array<{ name: string; id: string }> } = useApi(
    { states: [] },
    'states'
  )

  const [loadingCities, setLoadingCities] = useState(false)
  const [cities, setCities] = useState<Array<{ name: string; id: string }>>([])

  const workWithUs = pathname.includes('trabalhe-conosco')
  const postSales = pathname.includes('pos-obra')

  const { setLoading } = useContext(context)

  const mobile = !useMediaQuery({
    query: '(min-width: 951px)',
  })

  useLayoutEffect(() => {
    if (workWithUs || postSales) {
      const targetEl: HTMLElement = document.querySelector('#Formulario')
      if (targetEl) {
        setLoading(true)
        const f = setInterval(() => {
          const scrollY = targetEl.offsetTop
          const viewsCount = (document.querySelector('.views') as HTMLElement)
            .childElementCount
          if (viewsCount === 1 && document.body.offsetHeight >= scrollY) {
            scroller.scrollTo('Formulario', {
              offset: -0.65 * document.getElementById('menu-bar').offsetHeight,
            })
            setLoading(false)
            clearInterval(f)
          }
        }, 50)
      }
    }
  }, [])

  return (
    <section className={st.core} id="Formulario">
      <Tabs
        defaultPage={workWithUs ? 1 : postSales ? 2 : 0}
        pages={['Fale Conosco', 'Trabalhe Conosco', 'Pós-obra']}
        classes={{ content: st.content }}
      >
        <div>
          <div className={st.form}>
            <h2>Fale conosco</h2>
            <p>
              Entre em contato com nosso time de consultores para obter mais
              informações.
            </p>
            <Form
              path="contact-us"
              idPrefix="contact"
              button={<Button>Enviar mensagem</Button>}
              loadingButton={<Button>Enviando...</Button>}
              inputs={[
                {
                  name: 'name',
                  label: 'Nome*',
                },
                {
                  name: 'email',
                  label: 'E-mail*',
                  type: 'email',
                },
                {
                  name: 'whatsapp',
                  label: 'WhatsApp*',
                  mask: 'phone',
                },
                {
                  name: 'contactInterest',
                  label: 'Interesse do contato*',
                },
                {
                  name: 'message',
                  label: 'Mensagem*',
                  type: 'textarea',
                  className: 'wide',
                },
                {
                  name: 'privacy',
                  label: (
                    <>
                      Declaro que li e aceito a{' '}
                      <Link href="/politica-de-privacidade/">
                        Política de Privacidade
                      </Link>
                    </>
                  ),
                  type: 'checkbox',
                  className: 'wide',
                },
              ]}
            />
          </div>
          <div className={st.phones}>
            <h3>Entre em contato</h3>
            <p>Utilize nossos canais para entrar em contato.</p>
            <div className={st.links}>
              <Button
                href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
                  /\D/g,
                  ''
                )}`}
                external
                className={st.whatsapp}
              >
                {config.whatsapp}
              </Button>
              <Button href={`tel:${config.phone.replace(/\D/g, '')}`} external>
                {config.phone}
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className={st.form}>
            <h2>Trabalhe conosco</h2>
            <p>
              Procurando uma oportunidade para trabalhar com a Holder?
              <br />
              Deixe seus dados, venha fazer parte.
            </p>
            <Form
              path="work-with-us"
              idPrefix="curriculum"
              button={<Button>Enviar mensagem</Button>}
              loadingButton={<Button>Enviando...</Button>}
              inputs={[
                {
                  name: 'name',
                  label: 'Nome*',
                },
                {
                  name: 'email',
                  label: 'E-mail*',
                  type: 'email',
                },
                {
                  name: 'state',
                  label: 'Estado*',
                  type: 'select',
                  options: states.map(({ name, id: value }) => ({
                    name,
                    value,
                  })),
                  placeholder: 'Selecione',
                  onChange: (e) => {
                    setLoadingCities(true)
                    api('get', 'states/' + e.target.value).then(
                      ({ result: { cities } }) => {
                        setCities(cities)
                        setLoadingCities(false)
                      }
                    )
                  },
                },
                {
                  name: 'city',
                  label: 'Cidade*',
                  type: 'select',
                  options: cities.map(({ name, id: value }) => ({
                    name,
                    value,
                  })),
                  placeholder: loadingCities ? 'Carregando...' : 'Selecione',
                },
                {
                  name: 'whatsapp',
                  label: 'WhatsApp*',
                  mask: 'phone',
                },
                {
                  name: 'area',
                  label: 'Área de interesse*',
                  type: 'select',
                  options: areas.map(({ name, id: value }) => ({
                    name,
                    value,
                  })),
                  placeholder: 'Selecione',
                },
                {
                  name: 'file',
                  label: 'Currículo*',
                  type: 'file',
                  file: {
                    accept: 'application/msword, application/pdf',
                    button: (props) => (
                      <Button className={st1.btn} {...props}>
                        {mobile ? 'Anexar' : 'Anexar currículo'}
                      </Button>
                    ),
                  },
                  className: 'wide',
                },
                {
                  name: 'message',
                  label: 'Mensagem*',
                  type: 'textarea',
                  className: 'wide',
                },
                {
                  name: 'privacy',
                  label: (
                    <>
                      Declaro que li e aceito a{' '}
                      <Link href="/politica-de-privacidade/">
                        Política de Privacidade
                      </Link>
                    </>
                  ),
                  type: 'checkbox',
                  className: 'wide',
                },
              ]}
            />
          </div>
        </div>
        <div>
          <div className={st.form}>
            <h2>Pós-obra</h2>
            <p>
              Área exclusiva para clientes Holder. Clique abaixo para acessar.
            </p>
            <div className={st.centerBtn}>
              <Button
                href="https://cliente.fastbuilt.com.br/assistencia/holder"
                external
              >
                Área do cliente
              </Button>
            </div>
            {/*<Form
              path="after-sales"
              idPrefix="post-sales"
              button={<Button>Enviar mensagem</Button>}
              loadingButton={<Button>Enviando...</Button>}
              inputs={[
                {
                  name: 'name',
                  label: 'Nome*',
                },
                {
                  name: 'email',
                  label: 'E-mail*',
                  type: 'email',
                },
                {
                  name: 'whatsapp',
                  label: 'WhatsApp*',
                  mask: 'phone',
                },
                {
                  name: 'construction',
                  label: 'Empreendimento*',
                  type: 'select',
                  options: constructions.map(({ name, id: value }) => ({
                    name,
                    value,
                  })),
                  placeholder: 'Selecione',
                },
                {
                  name: 'unity',
                  label: 'Unidade*',
                  placeholder: 'Ex: Apartamento 806',
                },
                {
                  name: 'message',
                  label: 'Mensagem*',
                  type: 'textarea',
                  className: 'wide',
                },
                {
                  name: 'privacy',
                  label: (
                    <>
                      Declaro que li e aceito a{' '}
                      <Link href="/politica-de-privacidade/">
                        Política de Privacidade
                      </Link>
                    </>
                  ),
                  type: 'checkbox',
                  className: 'wide',
                },
              ]}
            />*/}
          </div>
        </div>
      </Tabs>
    </section>
  )
}

export default ContactForms
